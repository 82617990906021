<template>
  <div class="dump" :class="{ 'dump--show': isShow }">
    <div class="dump__wrapper">
      <button class="dump__close" type="button" @click="close"></button>
      <img src="@/assets/img/public/attention-icon.svg" alt="" class="dump__icon" />
      <div class="dump__text">
        Анкета сохраняет введенные данные, чтобы к заполнению можно было
        вернуться в любой удобный момент. Нажмите на "Сброс", чтобы очистить
        анкету
      </div>
      <button class="dump__button" type="button" @click="resetStorage">
        Сбросить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetStorage',
  props: ['resetDataForm'],
  mounted () {
    const storageKeys = this.$storage.keys();
    storageKeys.forEach((item) => {
      if (item.indexOf('public_') === 0) {
        this.isShow = true;
      }
    });
  },
  data () {
    return {
      isShow: false
    };
  },
  methods: {
    resetStorage () {
      this.isShow = false;
      this.resetDataForm();
    },
    close () {
      this.isShow = false;
    }
  }
};
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.dump
  position: fixed
  left: 0
  bottom: 0
  z-index: 20
  width: 100%
  background-color: $color-theme
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.15)
  border-radius: 10px 10px 0 0
  transform: translateY(101%)
  transition: transform 0.3s

.dump--show
  transform: translateY(0)

.dump__wrapper
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  max-width: 844px
  margin: 0 auto
  padding: 25px 20px

  +max-w($mobile_md)
    flex-direction: column
    padding: 35px 26px 20px

.dump__icon
  flex-shrink: 0
  width: 68px
  height: 68px
  margin-right: 23px

  +max-w($mobile_md)
    display: none

  svg
    width: 100%
    height: 100%

.dump__text
  max-width: 510px
  margin-right: 15px
  font-size: 16px
  line-height: 150%
  color: #fff

  +max-w($mobile_md)
    max-width: 100%
    margin-right: 0
    margin-bottom: 20px

.dump__button
  width: 170px
  margin-bottom: -30px
  padding: 6px
  border: 2px solid #fff
  box-sizing: border-box
  border-radius: 10px
  font-size: 18px
  font-weight: 600
  line-height: 24px
  text-align: center
  color: #fff
  transition: background-color 0.3s, color 0.3s

  +max-w($mobile_md)
    margin-bottom: 0

  &:hover
    background-color: #fff
    color: #2a6db1

.dump__close
  position: absolute
  top: 10px
  right: 20px
  width: 14px
  height: 14px
  padding: 3px
  box-sizing: content-box
  background-image: url("../assets/img/public/close-white-icon.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 14px
  transition: opacity 0.3s

  &:hover
    opacity: 0.7
</style>
