<template>
  <div class="public-body">
    <div class="public-body__block  public-body__block--decor">
      <a href="https://mkp-ruy.ru/" target="_blank">
        <img src="@/assets/img/mkp-logo.svg" alt="logo"/>
      </a>
    </div>
    <div class="public-body__block">
      <div class="public-body__title  title">Оформление карты</div>
      <div class="require-text">
        <sup>*</sup> - поля обязательные для заполнения
      </div>
      <form method="post" @submit.prevent="checkForm">
        <div class="form-group">
          <label for="lastname">Фамилия <sup>*</sup></label>
          <div class="form-control" :class="{ 'form-control--error': $v.form.lastname.$error }">
            <input
              id="lastname"
              type="text"
              class="form-control__input"
              name="lastname"
              placeholder="Введите вашу фамилию"
              v-model.trim="form.lastname"
              @change="$v.form.lastname.$touch()"
            />
          </div>
          <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.required">Обязательное поле</div>
          <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.ruAlpha">Принимаются только русские буквы алфавита</div>
        </div>
        <div class="form-group">
          <label for="firstname">Имя <sup>*</sup></label>
          <div class="form-control" :class="{ 'form-control--error': $v.form.firstname.$error }">
            <input
              id="firstname"
              type="text"
              class="form-control__input"
              name="firstname"
              placeholder="Введите ваше имя"
              v-model.trim="form.firstname"
              @change="$v.form.firstname.$touch()"
            />
          </div>
          <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.required">Обязательное поле</div>
          <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.ruAlpha">
            Принимаются только русские буквы алфавита
          </div>
        </div>
        <div class="form-group">
          <label for="middlename">Отчество</label>
          <div class="form-control" :class="{ 'form-control--error': $v.form.middlename.$error }">
            <input
              id="middlename"
              type="text"
              class="form-control__input"
              name="middlename"
              placeholder="Введите ваше отчество"
              v-model.trim="form.middlename"
              @change="$v.form.middlename.$touch()"
            />
          </div>
          <div class="form-group__error" v-if="$v.form.middlename.$dirty && !$v.form.middlename.ruAlpha">
            Принимаются только русские буквы алфавита
          </div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.form.dob.$error || $v.age.$invalid }">
          <label>Дата рождения <sup>*</sup></label>
          <date-picker
            @input="onCheckBirth"
            v-model="form.dob"
            name="dob"
            format="DD.MM.YYYY"
            value-type="DD.MM.YYYY"
            placeholder="дд.мм.гггг"
            :editable="true"
            ref="datepicker"
            :class="{ 'mx-datepicker--error': $v.form.dob.$error || $v.age.$invalid }"
          >
            <template #input="{ props, event }">
              <masked-input
                mask="11.11.1111"
                :value="props.value"
                v-bind="props"
                v-on="event"
                v-model="form.dob"
              />
            </template>
          </date-picker>
          <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.form.dob.required">Обязательное поле</div>
          <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.age.maxValue">Максимальный возраст оформления карты - 30 лет</div>
        </div>
        <div class="form-group">
          <label>Срок действия карты <sup>*</sup></label>
          <v-select
            label="name"
            :options="tariffOptions"
            :reduce="tariff => tariff.id"
            v-model="form.tariff"
            @change="$v.form.tariff.$touch()"
            :searchable="false"
            class="select"
            :class="{ 'select--error': $v.form.tariff.$error }"
            :get-option-label="getLabel"
            placeholder="Выберите значение"
            :readonly="!form.dob"
          >
            <template slot="open-indicator">
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
              </svg>
            </template>
            <template slot="option" slot-scope="option">
              <div class="select__item">{{ option.name }} ({{ option.price }} &#8381;)</div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="d-center">{{ option.name }} ({{ option.price }} &#8381;)</div>
            </template>
          </v-select>
          <div class="form-group__error" v-if="$v.form.tariff.$dirty && !$v.form.tariff.required">Обязательное поле</div>
        </div>
        <div class="form-group">
          <label>Телефон <sup>*</sup></label>
          <vue-tel-input
            :value="phoneValue"
            defaultCountry="RU"
            mode="international"
            type="tel"
            class="phonepicker"
            :class="{ 'phonepicker--error': $v.form.phone.$dirty && !isPhoneValid }"
            @validate="validedPhone"
            @blur="phoneBlur"
            disabledFetchingCountry
            validCharactersOnly
            dynamicPlaceholder
            autocomplete="phone_autocomplete_off"
          >
            <template slot="arrow-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                <path d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z" fill="#2B93E7"></path>
              </svg>
            </template>
          </vue-tel-input>
          <div class="form-group__error" v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
          <div class="form-group__error" v-if="$v.form.phone.$dirty && !isPhoneValid">Недопустимое значение для номера телефона</div>
        </div>

        <div class="form-group">
          <label for="email">Электронная почта <sup>*</sup></label>
          <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
            <input
              id="email"
              type="email"
              name="email"
              class="form-control__input"
              placeholder="Введите ваш email"
              v-model.trim="form.email"
              @change="$v.form.email.$touch()"
            />
          </div>
          <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
          <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
            Введите правильный формат почты
          </div>
        </div>
        <div class="form-group">
          <label for="email_repeat">Повторите адрес электронной почты <sup>*</sup></label>
          <div class="form-control" :class="{ 'form-control--error': $v.form.emailRepeat.$error }">
            <input
              @paste.prevent
              autocomplete="autocomplete_off_email"
              id="email_repeat"
              type="email"
              name="email_repeat"
              class="form-control__input"
              placeholder="Повторите ваш email"
              v-model.trim="form.emailRepeat"
              @change="$v.form.emailRepeat.$touch()"
            />
          </div>
          <div class="form-group__error" v-if="$v.form.emailRepeat.$dirty && !$v.form.emailRepeat.required">
            Обязательное поле
          </div>
          <div class="form-group__error" v-if="$v.form.emailRepeat.$dirty && !$v.form.emailRepeat.email">
            Введите правильный формат почты
          </div>
          <div class="form-group__error" v-if=" $v.form.emailRepeat.$dirty && !$v.form.emailRepeat.sameAsEmail">
            Электронная почта не соответствует указанной выше
          </div>
        </div>
        <div class="form-group">
          <label>Регион <sup>*</sup></label>
          <v-select
            :options="regionOptions"
            label="name"
            :reduce="region => region.id"
            v-model="form.region"
            :filterable="false"
            :clearable="false"
            @search="onRegionSearch"
            class="select"
            :get-option-label="getLabel"
            :class="{ 'select--error': $v.form.region.$error }"
            placeholder="Выберите регион"
          >
            <template slot="open-indicator">
              <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </template>
            <template slot="no-options">введите для поиска свой регион..</template>
            <template slot="option" slot-scope="option">
              <div class="select__item">{{ option.name }}</div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="d-center">{{ option.name }}</div>
            </template>
          </v-select>
          <div class="form-group__error" v-if="$v.form.region.$dirty && !$v.form.region.required">Обязательное поле</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.form.coords.$error }">
          <label>Фотография <sup>*</sup></label>
          <cropper-modal
            :getCropCoords="getCropCoords"
            :getCropHash="getCropHash"
            :stencilProps="{ aspectRatio: 3 / 4 }"
            ref="cropperModal"
          />
          <div class="form-group__error" v-if="$v.form.coords.$dirty && !$v.form.coords.required">Обязательное поле</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.form.passport.$error }">
          <label>Скан-копия паспорта (основной разворот) <sup>*</sup></label>
          <uploader :getFileHash="getFileHash" limit-files="1" :fileUrl="fileUrl" />
          <div class="form-group__error" v-if="$v.form.passport.$dirty && !$v.form.passport.required">Обязательное поле</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.form.type_delivery.$error }">
          <label>Способ получения карты <sup>*</sup></label>
          <div class="form-group__party">
            <label class="radio">
              <div class="radio__text">Самовывоз</div>
              <input type="radio" name="delivery" v-model.number="form.type_delivery" value="1"/>
              <div class="radio__radiomark"></div>
            </label>
            <label class="radio">
              <div class="radio__text">Почта России</div>
              <input type="radio" name="delivery" v-model.number="form.type_delivery" value="2" />
              <div class="radio__radiomark"></div>
            </label>
          </div>
          <div class="form-group__error" v-if="$v.form.type_delivery.$dirty && !$v.form.type_delivery.required">
            Обязательное поле
          </div>
        </div>
        <template v-if="form.type_delivery === 1">
          <div class="form-group  form-group--readonly">
            <label>Город для самовывоза:</label>
            <v-select
              label="name"
              :options="pickupOptions"
              :reduce="pickup_city => pickup_city.id"
              v-model="form.pickup_city"
              :searchable="false"
              class="select"
              :get-option-label="getLabel"
              placeholder="Выберите регион для самовывоза"
              readonly
            >
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
        </template>
        <template v-if="form.type_delivery === 2">
          <div class="form-group" :class="{ 'form-group--error': $v.form.delivery.$error }">
            <label>Адрес доставки</label>
            <v-select
              label="address"
              v-model="form.delivery"
              :filterable="false"
              :options="deliveryRegionOptions"
              @search="onDeliveryRegionSearch"
              :get-option-label="getLabel"
              placeholder="Введите свой адрес"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">введите для поиска свой адрес..</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
            <div class="form-group__info" v-if="form.delivery">
              {{ form.delivery.address.length > 64 ? form.delivery.address : "" }}
            </div>
            <div class="form-group__error" v-if="$v.form.delivery.$dirty && !$v.form.delivery.required">
              Обязательное поле
            </div>
          </div>
        </template>

        <div class="form-group" :class="{ 'form-group--error': $v.form.policy.$error }">
          <div class="form-group__place">
            <label class="checkbox">
              <div class="checkbox__text">
                Я ознакомился (ась) с
                <router-link :to="{ name: 'privacy' }" target="_blank">
                  политикой в отношении обработки персональных данных
                </router-link>
                &nbsp;<sup>*</sup>
              </div>
              <input type="checkbox" name="policy" v-model="form.policy" @change="$v.form.policy.$touch()" />
              <div class="checkbox__checkmark"></div>
            </label>
          </div>
          <div class="form-group__error" v-if="$v.form.policy.$dirty && !$v.form.policy.sameAs">Обязательное поле</div>
        </div>

        <div class="form-group" :class="{ 'form-group--error': $v.form.conditions.$error }">
          <div class="form-group__place">
            <label class="checkbox">
              <div class="checkbox__text">
                Я ознакомился (ась) с
                <router-link :to="{ name: 'dos' }" target="_blank">
                  условиями возврата, доставки и процесса описания передачи данных
                </router-link>&nbsp;<sup>*</sup>
              </div>
              <input type="checkbox" name="conditions" v-model="form.conditions" @change="$v.form.conditions.$touch()" />
              <div class="checkbox__checkmark"></div>
            </label>
          </div>
          <div class="form-group__error" v-if="$v.form.conditions.$dirty && !$v.form.conditions.sameAs">Обязательное поле</div>
        </div>

        <button type="submit" class="button" ref="submit" :disabled="$v.form.$error || $v.age.$invalid">
          <span>Купить карту</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader" />
        </button>
        <reset-storage :resetDataForm="resetDataForm"/>
      </form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {required, sameAs, email, helpers, requiredIf, maxValue} from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import Uploader from '@/components/Uploader';
import CropperModal from '@/components/Cropper';
import { debounce, omit } from 'lodash';
import ResetStorage from '@/components/ResetStorage';
import MaskedInput from 'vue-masked-input';
import { VueTelInput } from 'vue-tel-input';

const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i);

export default {
  name: 'Card',
  mixins: [ validationMixin ],
  components: {
    DatePicker,
    CropperModal,
    Uploader,
    ResetStorage,
    MaskedInput,
    VueTelInput
  },
  created () {
    this.$storage.setOptions({ prefix: 'public_' });
  },
  beforeMount () {
    this.fetchTariffs();
    this.onRegionSearch('', () => true);
  },
  mounted () {
    const storageKeys = this.$storage.keys();
    storageKeys.forEach((item) => {
      if (item.indexOf('public_') === 0) {
        this.$v.form.$touch();
        this.form.tariff = this.$storage.get('tariff');
        this.form.lastname = this.$storage.get('lastname');
        this.form.firstname = this.$storage.get('firstname');
        this.form.middlename = this.$storage.get('middlename');
        this.form.dob = this.$storage.get('dob');
        this.form.email = this.$storage.get('email');
        this.form.emailRepeat = this.$storage.get('emailRepeat');
        this.form.region = this.$storage.get('region');
        this.form.type_delivery = this.$storage.get('type_delivery');
        this.form.delivery = this.$storage.get('delivery');
      }
    });
  },
  data () {
    return {
      form: {
        tariff: null,
        lastname: '',
        firstname: '',
        middlename: '',
        dob: '',
        phone: null,
        email: '',
        emailRepeat: '',
        region: null,
        type_delivery: null,
        pickup_city: 1,
        delivery: null,
        photo: '',
        passport: null,
        coords: {},
        policy: true,
        conditions: true
      },
      tariffOptions: [],
      regionOptions: [],
      deliveryRegionOptions: [],
      pickupOptions: [{ id: 1, name: 'Москва' }],
      isPhoneValid: null,
      phoneValue: '',
      openDatepicker: false,
      fileUrl: `${process.env.VUE_APP_PUBLIC_URL}card/files`,
      age: 0,
    };
  },
  computed: {
    ageDelta() {
      return 31 - this.age;
    },
  },
  watch: {
    'form.tariff' (value) {
      if (value !== this.$storage.get('tariff')) {
        this.$storage.set('tariff', value);
      }
    },
    'form.lastname' (value) {
      if (value !== this.$storage.get('lastname')) {
        this.$storage.set('lastname', value);
      }
    },
    'form.firstname' (value) {
      if (value !== this.$storage.get('firstname')) {
        this.$storage.set('firstname', value);
      }
    },
    'form.middlename' (value) {
      if (value !== this.$storage.get('middlename')) {
        this.$storage.set('middlename', value);
      }
    },
    'form.dob' (value) {
      if (value !== this.$storage.get('dob')) {
        this.$storage.set('dob', value);
      }
      this.fetchTariffs();
    },
    'form.email' (value) {
      if (value !== this.$storage.get('email')) {
        this.$storage.set('email', value);
      }
    },
    'form.emailRepeat' (value) {
      if (value !== this.$storage.get('emailRepeat')) {
        this.$storage.set('emailRepeat', value);
      }
    },
    'form.region' (value) {
      if (value !== this.$storage.get('region')) {
        this.$storage.set('region', value);
      }
    },
    'form.type_delivery' (value) {
      if (value !== this.$storage.get('type_delivery')) {
        this.$storage.set('type_delivery', value);
      }
    },
    'form.delivery' (value) {
      if (value !== this.$storage.get('delivery')) {
        this.$storage.set('delivery', value);
      }
    }
  },
  validations: {
    form: {
      tariff: { required },
      lastname: { required, ruAlpha },
      firstname: { required, ruAlpha },
      middlename: { ruAlpha },
      dob: { required },
      phone: { required },
      email: { required, email },
      emailRepeat: { required, email, sameAsEmail: sameAs('email') },
      region: { required },
      type_delivery: { required },
      coords: { required },
      passport: { required },
      policy: { sameAs: val => val === true },
      conditions: { sameAs: val => val === true },
      delivery: { required: requiredIf(function () {
        return this.form.type_delivery === 2;
      })},
    },
    age: { maxValue: maxValue(30) },
  },
  methods: {
    fetchTariffs() {
      this.$store.dispatch('overall/GET_TARIFFS')
        .then(response => {
          this.tariffOptions = response.data.results.filter(tariff => tariff.count_year <= this.ageDelta);
        });
    },
    onRegionSearch (search, loading) {
      loading(true);
      this.searchRegion(loading, search, this);
    },
    searchRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('overall/GET_REGIONS', {search}).then(response => {
        vm.regionOptions = response.data;
        loading(false);
      });
    }, 350),
    onDeliveryRegionSearch (search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('overall/GET_ADDRESS', {search}).then(response => {
        vm.deliveryRegionOptions = response.data;
        loading(false);
      });
    }, 350),
    getCropCoords (value) {
      this.form.coords = value;
      this.$v.form.coords.$touch();
    },
    getFileHash (value) {
      this.form.passport = value;
    },
    getCropHash (value) {
      this.form.photo = value;
    },
    checkForm () {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.$refs.cropperModal.sendCrop()
          .then(() => {
            this.sendForm();
          });
      }
    },
    sendForm () {
      if (this.form.type_delivery === 1) {
        this.form = omit(this.form, ['delivery']);
      } else {
        this.form = omit(this.form, ['pickup_city']);
      }
      this.$refs.submit.classList.add('preload');
      this.$store
        .dispatch('overall/POST_FORM_CARD', this.form)
        .then(response => {
          this.$router.push({
            name: 'card-success',
            params: {
              hash: response.data.hash,
              image: response.data.test_card.file
            }
          });
          this.$refs.submit.classList.remove('preload');
          this.resetDataForm();
        })
        .catch(response => {
          this.$refs.submit.classList.remove('preload');
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
              ` exist in options object ${JSON.stringify(option)}.\n` +
              'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    resetDataForm () {
      this.form.tariff = null;
      this.form.lastname = null;
      this.form.firstname = null;
      this.form.middlename = null;
      this.form.dob = null;
      this.form.email = null;
      this.form.emailRepeat = null;
      this.form.region = null;
      this.form.type_delivery = null;
      this.form.delivery = null;
      this.$storage.clear();
      this.$v.form.$reset();
    },
    validedPhone (number) {
      this.isPhoneValid = number.valid;

      if (number.valid) {
        this.form.phone = number.number.e164;
      } else {
        this.form.phone = null;
      }
    },
    phoneBlur () {
      this.$v.form.phone.$touch();
    },
    onCheckBirth() {
      this.$v.form.dob.$touch();
      if (this.form.dob) {
        const today = new Date();
        const formatBirth = this.form.dob.slice(3, 6) + this.form.dob.slice(0, 3) + this.form.dob.slice(6);
        const birthDate = new Date(formatBirth);
        this.age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) this.age--;
      } else {
        this.age = 0;
      }
    },
  }
};
</script>
